import "../App.css";
import UsersTableBody from "../components/UsersTableBody";
import { useState, useEffect } from "react";
import axios from "axios";
import Header from "../components/Header";

function ManageUsers() {
  const [users, setUsers] = useState([]);
  const baseURI = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const dataFetch = async () => {
      const requestData = new FormData();
      requestData.append("URI", "getuserlist");
      let data = await axios.post(baseURI, requestData);
      if (data) setUsers(data.data);
    };
    dataFetch();
  }, []);
  return (
    <div className="App">
      <Header></Header>
      <div className="container">
        <div className="table-wrapper">
          <div className="table-title">
            <div className="row">
              <div className="col-sm-12">
                <h2>
                  Manage <b>Users({users.length})</b>
                </h2>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>UserName</th>
                  <th>Email</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => {
                  return (
                    <UsersTableBody data={user} key={index}></UsersTableBody>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageUsers;
