
function UsersTableBody(props) {
  return (
    <tr>
      <td>
        <input
          type="text"
          className="form-control"
          disabled={true}
          value={props.data.username}
        />
      </td>
      <td>
        <textarea
          className="form-control"
          rows="2"
          disabled={true}
          value={props.data.email}
        ></textarea>
      </td>
      <td>
        <textarea
          className="form-control"
          rows="2"
          disabled={true}
          value={props.data.create_datetime}
        ></textarea>
      </td>
    </tr>
  );
}

export default UsersTableBody;
