import "../App.css";
import { useState, useEffect } from "react";
import axios from "axios";
import Header from "../components/Header";
import FilesTableBody from "../components/FilesTableBody";

function TrainChatGPT() {
  const [selectedFile, setSelectedFile] = useState("");
  const [files, setFiles] = useState([]);
  const baseURI = process.env.REACT_APP_AI_URL + "api/";
  const handleInputChange = (event) => {
    setSelectedFile(event.target.files);
  };

  useEffect(() => {
    const dataFetch = async () => {
      const data =  await axios.get(baseURI);
      console.log(data);
      if(data) setFiles(Object.values(data.data.data));
    }
    dataFetch();
  },[])
  const onSubmit = () => {
    if (!selectedFile) {
      alert("Please select a file!");
      return false;
    }
    const data = new FormData();
    data.append("file", selectedFile[0]);
    axios
      .post(baseURI+"upload", data)
      .then(
        (res) => {
          console.log(res);
          if (res.status === 200) {
            alert("File Upload Success, Page would be rerenderd!");
            window.location.reload();
          } else {
            alert("Something went wrong");
          }
        },
        (error) => {
          alert(error);
        }
      );
  };


  return (
    <div className="App">
      <Header></Header>
      <div className="container">
        <div className="table-wrapper">
          <div className="table-title">
            <div className="row">
              <div className="col-sm-12">
                <h2>
                  <button
                    style={{float:'right'}}
                    type="submit"
                    className="btn btn-primary"
                    onClick={onSubmit}
                  >
                    Upload Pdf
                  </button>
                  <input
                    type="file"
                    name="pdf_file"
                    style={{marginTop:'5px'}}
                    accept="application/pdf"
                    placeholder="Select a PDF file"
                    required=""
                    onChange={handleInputChange}
                  />
                </h2>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th style={{width:'80%'}}>File Name</th>
                </tr>
              </thead>
              <tbody>
                {files.map((file, index) => {
                  return (
                    <FilesTableBody data={file} key={index}></FilesTableBody>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrainChatGPT;
