function Header() {
  return (
    <div className="container">
      <header style={{alignItems:'center', display:'flex', justifyContent:"center", padding:'20px'}}>
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a href="/" className="nav-link active" aria-current="page">
              Manage Contexts
            </a>
          </li>
          <li className="nav-item">
            <a href="/usermanage" className="nav-link">
              Manage Users
            </a>
          </li>
          <li className="nav-item">
            <a href="/trainchatgpt" className="nav-link">
              Train ChatGPT
            </a>
          </li>
          <li className="nav-item">
            <a href="https://constructgpt.com/" className="nav-link">
              ConstructGPT Website
            </a>
          </li>
        </ul>
      </header>
    </div>
  );
}

export default Header;
