function UsersTableBody(props) {
  return (
    <tr>
      <td>
        <textarea
          type="text"
          rows="1"
          className="form-control"
          disabled={true}
          value={props.data}
        />
      </td>
    </tr>
  );
}

export default UsersTableBody;
