import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ManageContext from "./pages/ManageContext";
import ManageUsers from "./pages/ManageUsers";
import TrainChatGPT from "./pages/TrainChatGPT";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ManageContext />}/>
        <Route path="/usermanage" element={<ManageUsers />} />
        <Route path="/trainchatgpt" element={<TrainChatGPT />} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);