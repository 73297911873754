import "../App.css";
import ContextTableBody from "../components/ContextTableBody";
import { useState, useEffect } from "react";
import axios from "axios";
import Header from "../components/Header";

function ManageContext() {
  const [contexts, setContexts] = useState([]);
  const baseURI = process.env.REACT_APP_API_URL;
  const addContext = () => {
    setContexts([...contexts, {
      'ContextID':'', 'Context':'','ID':null
    }]);
  }
  
  useEffect(() => {
    const dataFetch = async () => {
      const data =  await axios.get(baseURI);
      if(data) setContexts(data.data);
    }
    dataFetch();
  },[])

  const deleteContext = (index) => {
    if(contexts[index].ID === null) {
      setContexts(contexts.filter((item, i) => i !== index));
    } else {
      const requestData = new FormData();
      requestData.append("URI", "delete");
      requestData.append("ID", contexts[index].ID);
      axios.post(baseURI,requestData).then((response) => {
        setContexts(contexts.filter((item, i) => item.ID !== contexts[index].ID));
      });
    }
  }

  const saveContext = (index, data) => {
    console.log(index, data);
    console.log(contexts[index]);
    if(contexts[index].ID === null) {
      //Add
      const requestData = new FormData();
      requestData.append("URI", "addContext");
      requestData.append("ContextID", data.ContextID);
      requestData.append("Context", data.Context);
      requestData.append("ID", contexts[index].ID);
      axios.post(baseURI, requestData).then((response) => {
        
      });
    }else {
      //Edit
      const requestData = new FormData();
      requestData.append("URI", "update");
      requestData.append("ContextID", data.ContextID);
      requestData.append("Context", data.Context);
      requestData.append("ID", contexts[index].ID);

      axios.post(baseURI, requestData).then((response) => {

      });
    } 
  }
  return (
    <div className="App">
      <Header></Header>
      <div className="container">
        <div className="table-wrapper">
          <div className="table-title">
            <div className="row">
              <div className="col-sm-12">
                <h2>
                  Manage <b>Contexts</b>
                  {/* <button type="button" className="btn btn-primary btn_add" onClick={addContext}>Add</button> */}
                </h2>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Context</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  contexts.map((context, index) => {
                    return (
                      <ContextTableBody data={context} key={index} index={index} deleteContext={deleteContext} saveContext={saveContext}></ContextTableBody>
                    );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageContext;
